
import { defineComponent, onBeforeMount, ref } from 'vue'
import axios from '@/http'
import { useRoute, useRouter } from 'vue-router'
import Lot from '@/components/lot/Lot.vue'
import { ILot } from '@/interfaces'

export default defineComponent({
	name: 'LotUpdate',

	components: { Lot },

	setup() {
		const route = useRoute()
		const router = useRouter()
		let lot = ref<ILot | null>(null)

		onBeforeMount(async () => {
			try {
				lot.value = (await axios.get('/lot/get-by-id/' + route.params.id)).data
			} catch (e) {
				router.push({ name: 'Error' })
			}
		})

		return {
			lot
		}
	}
})
