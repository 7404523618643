import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "common-h1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lot = _resolveComponent("Lot")!

  return (_ctx.lot)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("h1", _hoisted_1, "Обзор партии " + _toDisplayString(_ctx.lot.name) + " " + _toDisplayString(_ctx.lot.id), 1),
        _createVNode(_component_Lot, { "lot-prop": _ctx.lot }, null, 8, ["lot-prop"])
      ], 64))
    : _createCommentVNode("", true)
}